import * as _ from 'lodash';
import {
  SEARCHING_STICKY_ATC,
  STICKY_ATC_ACTUALLY_MODIFY_BOOL,
  STICKY_ATC_ACTUALLY_MODIFY_NUM,
  STICKY_ATC_ACTUALLY_MODIFY_WIDGET_BOOL,
  STICKY_ATC_CREATING_STORE_JS,
  STICKY_ATC_GETTING_ALL_STORES,
  STICKY_ATC_MODIFYING_BOOL,
  STICKY_ATC_MODIFYING_NUM,
  STICKY_ATC_SEARCH_FAILURE,
  STICKY_ATC_SEARCH_SUCCESS,
  STICKY_ATC_SET_ALL_STORES,
  STICKY_ATC_SHOP_NAME_CHANGED,
  STICKY_ATC_WIDGET_TEXT_CHANGE,
  STICKY_ATC_WIDGET_DEV_SHOPS_ACTION_LOADING,
  STICKY_ATC_WIDGET_DEV_SHOPS,
  STICKY_ATC_SET_ALL_PACKAGES,
  STICKY_ATC_GETTING_ALL_PACKAGES,
  STICKY_ATC_SET_ALL_COUPONS,
  STICKY_ATC_MODIFY_STRING,
  STICKY_ATC_GETTING_ALL_COUPONS, SET_SUBSCRIBED_NO_VIEWS_JSON, STICKY_ATC_SUB_NO_VIEWS_LOADING
} from '../actions/stickyAtc';

const stickyAtc = (state = {}, action) => {
  let newState = null;
  switch (action.type) {
    case STICKY_ATC_GETTING_ALL_STORES:
      newState = _.cloneDeep(state);
      newState.gettingAllStores = true;
      break;
    case STICKY_ATC_CREATING_STORE_JS:
      newState = _.cloneDeep(state);
      newState.creatingStoreJs = action.bool;
      break;
    case STICKY_ATC_MODIFYING_BOOL:
      newState = _.cloneDeep(state);
      newState.modifyingBoolean = newState.modifyingBoolean || {};
      newState.modifyingBoolean[action.attribute] = action.bool;
      break;
    case STICKY_ATC_MODIFYING_NUM:
      newState = _.cloneDeep(state);
      newState.modifyingNumber = newState.modifyingNumber || {};
      newState.modifyingNumber[action.attribute] = action.bool;
      break;
    case STICKY_ATC_ACTUALLY_MODIFY_BOOL:
      newState = _.cloneDeep(state);
      newState.shopConfig[action.attribute] = action.bool;
      break;
    case STICKY_ATC_ACTUALLY_MODIFY_WIDGET_BOOL:
      newState = _.cloneDeep(state);
      newState.shopConfig[action.attribute] = action.bool;
      break;
    case STICKY_ATC_ACTUALLY_MODIFY_NUM:
      newState = _.cloneDeep(state);
      newState.shopConfig[action.attribute] = action.num;
      break;
    case STICKY_ATC_MODIFY_STRING:
      newState = _.cloneDeep(state);
      newState.shopConfig[action.attribute] = action.text;
      break;
    case STICKY_ATC_SHOP_NAME_CHANGED:
      newState = _.cloneDeep(state);
      newState.domain = action.text;
      break;
    case SEARCHING_STICKY_ATC:
      newState = _.cloneDeep(state);
      newState.searchingStore = true;
      newState.adminLink = null;
      break;
    case STICKY_ATC_SET_ALL_STORES:
      newState = _.cloneDeep(state);
      newState.gettingAllStores = false;
      newState.stores = action.json.shop_names.map((s) => ({
        key: s,
        value: s,
      }));
      newState.booleans = _.cloneDeep(action.json.booleans);
      newState.modifiable_numbers = _.cloneDeep(action.json.modifiable_numbers);
      newState.modifiable_booleans = _.cloneDeep(
        action.json.modifiable_booleans
      );
      newState.modifiable_strings = _.cloneDeep(
        action.json.modifiable_strings
      );
      break;
    case STICKY_ATC_SEARCH_SUCCESS:
      newState = _.cloneDeep(state);
      newState.searchingStore = false;
      newState.shopData = _.cloneDeep(action.json.shopData);
      newState.shopConfig = _.cloneDeep(action.json.shopConfig);
      newState.cssThemesOptions = _.values(action.json.css_themes);
      break;
    case STICKY_ATC_SEARCH_FAILURE:
      newState = _.cloneDeep(state);
      newState.searchingStore = false;
      break;
    case STICKY_ATC_WIDGET_TEXT_CHANGE:
      newState = _.cloneDeep(state);
      newState.shopConfig[action.attr] = action.text;
      break;
    case STICKY_ATC_WIDGET_DEV_SHOPS_ACTION_LOADING:
      newState = _.cloneDeep(state);
      newState.devShopsActionLoading = action.loading;
      break;
    case STICKY_ATC_SUB_NO_VIEWS_LOADING:
      newState = _.cloneDeep(state);
      newState.subNoViewsLoading = action.loading;
      break;
    case SET_SUBSCRIBED_NO_VIEWS_JSON:
      newState = _.cloneDeep(state);
      {
        const shopsWithViews = new Set(action.json.viewsJson.map(view => view.shop_name));
        newState.shopsWithoutViews = action.json.subscribedShops
          .map(shop => shop.shop)
          .filter(shop => !shopsWithViews.has(shop));
      }
      break;
    case STICKY_ATC_WIDGET_DEV_SHOPS:
      newState = _.cloneDeep(state);
      newState.devShops = action.shops;
      break;
    case STICKY_ATC_SET_ALL_PACKAGES:
      newState = _.cloneDeep(state);
      newState.packages = action.json;
      newState.gettingAllPackages = false;
      break;
    case STICKY_ATC_GETTING_ALL_PACKAGES:
      newState = _.cloneDeep(state);
      newState.gettingAllPackages = true;
      break;
    case STICKY_ATC_SET_ALL_COUPONS:
      newState = _.cloneDeep(state);
      newState.coupons = action.json;
      newState.gettingAllCoupons = false;
      break;
    case STICKY_ATC_GETTING_ALL_COUPONS:
      newState = _.cloneDeep(state);
      newState.gettingAllCoupons = true;
      break;
    default:
      return state;
  }
  return newState;
};

export default stickyAtc;
