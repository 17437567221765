import {
  activateToast,
  fetchNewStickyAtcApi,
  fetchStickyAtcApi,
  fetchToken,
  postNewStickyAtcApi,
  updateNewStickyAtcApi,
  actionNewStickyAtcApi
} from './main';

export const STICKY_ATC_GETTING_ALL_STORES = 'STICKY_ATC_GETTING_ALL_STORES';
export const STICKY_ATC_CREATING_STORE_JS = 'STICKY_ATC_CREATING_STORE_JS';
export const STICKY_ATC_MODIFYING_BOOL = 'STICKY_ATC_MODIFYING_BOOL';
export const STICKY_ATC_MODIFYING_NUM = 'STICKY_ATC_MODIFYING_NUM';
export const STICKY_ATC_ACTUALLY_MODIFY_BOOL =
  'STICKY_ATC_ACTUALLY_MODIFY_BOOL';
export const STICKY_ATC_ACTUALLY_MODIFY_WIDGET_BOOL =
  'STICKY_ATC_ACTUALLY_MODIFY_WIDGET_BOOL';
export const STICKY_ATC_ACTUALLY_MODIFY_NUM = 'STICKY_ATC_ACTUALLY_MODIFY_NUM';
export const STICKY_ATC_MODIFY_STRING = 'STICKY_ATC_MODIFY_STRING';
export const STICKY_ATC_SET_ALL_STORES = 'STICKY_ATC_SET_ALL_STORES';
export const STICKY_ATC_SEARCH_SUCCESS = 'STICKY_ATC_SEARCH_SUCCESS';
export const STICKY_ATC_SHOP_NAME_CHANGED = 'STICKY_ATC_SHOP_NAME_CHANGED';
export const STICKY_ATC_WIDGET_TEXT_CHANGE = 'STICKY_ATC_WIDGET_TEXT_CHANGE';
export const SEARCHING_STICKY_ATC = 'SEARCHING_STICKY_ATC';
export const STICKY_ATC_SEARCH_FAILURE = 'STICKY_ATC_SEARCH_FAILURE';
export const STICKY_ATC_WIDGET_DEV_SHOPS_ACTION_LOADING = 'STICKY_ATC_WIDGET_DEV_SHOPS_ACTION_LOADING';
export const STICKY_ATC_SUB_NO_VIEWS_LOADING = 'STICKY_ATC_SUB_NO_VIEWS_LOADING';
export const SET_SUBSCRIBED_NO_VIEWS_JSON = 'SET_SUBSCRIBED_NO_VIEWS_JSON';
export const STICKY_ATC_WIDGET_DEV_SHOPS = 'STICKY_ATC_WIDGET_DEV_SHOPS';
export const STICKY_ATC_SET_ALL_PACKAGES = 'STICKY_ATC_SET_ALL_PACKAGES';
export const STICKY_ATC_GETTING_ALL_PACKAGES = 'STICKY_ATC_GETTING_ALL_PACKAGES';
export const STICKY_ATC_GETTING_ALL_COUPONS = 'STICKY_ATC_GETTING_ALL_COUPONS';
export const STICKY_ATC_SET_ALL_COUPONS = 'STICKY_ATC_SET_ALL_COUPONS';

export const stickyAtcGettingAllStores = () => ({
  type: STICKY_ATC_GETTING_ALL_STORES,
});

export const stickyAtcCreatingStoreJs = (bool) => ({
  type: STICKY_ATC_CREATING_STORE_JS,
  bool,
});

export const stickyAtcModifyingBool = (attribute, bool) => ({
  type: STICKY_ATC_MODIFYING_BOOL,
  attribute,
  bool,
});

export const stickyAtcModifyingNum = (attribute, bool) => ({
  type: STICKY_ATC_MODIFYING_NUM,
  attribute,
  bool,
});

export const stickyAtcActuallyModifyBool = (attribute, bool) => ({
  type: STICKY_ATC_ACTUALLY_MODIFY_BOOL,
  attribute,
  bool,
});

export const stickyAtcActuallyModifyWidgetBool = (attribute, bool) => ({
  type: STICKY_ATC_ACTUALLY_MODIFY_WIDGET_BOOL,
  attribute,
  bool,
});

export const stickyAtcActuallyModifyNum = (attribute, num) => ({
  type: STICKY_ATC_ACTUALLY_MODIFY_NUM,
  attribute,
  num,
});

export const stickyAtcModifyString = (attribute, text) => ({
  type: STICKY_ATC_MODIFY_STRING,
  attribute,
  text,
});

export const searchingStickyAtc = () => ({
  type: SEARCHING_STICKY_ATC,
});

export const stickyAtcSearchFailure = () => ({
  type: STICKY_ATC_SEARCH_FAILURE,
});

export const stickyAtcSetAllStores = (json) => ({
  type: STICKY_ATC_SET_ALL_STORES,
  json,
});

export const stickyAtcSearchSuccess = (json) => ({
  type: STICKY_ATC_SEARCH_SUCCESS,
  json,
});

export const stickyAtcShopNameChanged = (text) => ({
  type: STICKY_ATC_SHOP_NAME_CHANGED,
  text,
});

export const stickyAtcWidgetTextChange = (attr, text) => ({
  type: STICKY_ATC_WIDGET_TEXT_CHANGE,
  attr,
  text,
});

export const stickyAtcWidgetDevShopsActionLoading = (loading) => ({
  type: STICKY_ATC_WIDGET_DEV_SHOPS_ACTION_LOADING,
  loading,
});

export const stickyAtcSubscribedNoViewsLoading = (loading) => ({
  type: STICKY_ATC_SUB_NO_VIEWS_LOADING,
  loading,
});

export const setSubscribedNoViewsJson = (json) => ({
  type: SET_SUBSCRIBED_NO_VIEWS_JSON,
  json,
});

export const stickyAtcWidgetDevShops = (shops) => ({
  type: STICKY_ATC_WIDGET_DEV_SHOPS,
  shops,
});


export const stickyAtcSetAllPackages = (json) => ({
  type: STICKY_ATC_SET_ALL_PACKAGES,
  json,
});


export function stickyAtcGettingAllPackages() {
  return {
    type: STICKY_ATC_GETTING_ALL_PACKAGES,
  };
}

export function stickyAtcGettingAllCoupons() {
  return {
    type: STICKY_ATC_GETTING_ALL_COUPONS,
  };
}


export function stickyAtcSetAllCoupons(json) {
  return {
    type: STICKY_ATC_SET_ALL_COUPONS,
    json,
  };
}


export function getAllStickyAtcStores(token) {
  return async (dispatch, getState) => {
    dispatch(stickyAtcGettingAllStores());
    const response = await fetchNewStickyAtcApi(`api/shop`, token);
    if (response.ok) {
      const json = await response.json();
      dispatch(stickyAtcSetAllStores(json));
    } else {
      console.log('getAllStickyAtcStores error');
    }
  };
}

export function stickyAtcSearchShop(token) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(searchingStickyAtc());
    const response = await fetchNewStickyAtcApi(
      `api/shop?shop=${state.stickyAtc.domain}`,
      token
    );
    if (response.ok) {
      const json = await response.json();
      dispatch(stickyAtcSearchSuccess(json));
    } else {
      dispatch(stickyAtcSearchFailure());
      dispatch(activateToast('Failed to fetch store :('));
    }
  };
}

export function stickyAtcCreateStoreJs(token) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(stickyAtcCreatingStoreJs(true));
    const response = await postNewStickyAtcApi(
      'api/create-store-js',
      {
        shop: state.stickyAtc.domain,
      },
      token
    );
    dispatch(stickyAtcCreatingStoreJs(false));
    if (response.ok) {
      dispatch(activateToast('Success :)'));
    } else {
      dispatch(activateToast('Failed :('));
    }
  };
}

export function stickyAtcModifyBool(attribute, bool, token) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(stickyAtcModifyingBool(attribute, true));
    const response = await updateNewStickyAtcApi(
      'api/shop',
      {
        shop: state.stickyAtc.domain,
        [attribute]: bool,
      },
      token
    );
    dispatch(stickyAtcModifyingBool(attribute, false));
    if (response.ok) {
      dispatch(activateToast('Success :)'));
      dispatch(stickyAtcActuallyModifyBool(attribute, bool));
    } else {
      dispatch(activateToast('Failed :('));
    }
  };
}

export function stickyAtcModifyWidgetBool(attribute, bool) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(stickyAtcModifyingBool(attribute, true));
    const response = await fetchStickyAtcApi(
      'sticky_atc_crm/change_widget_attr',
      {
        shop_name: state.stickyAtc.domain,
        token: await fetchToken(),
        key: attribute,
        value: bool,
      }
    );
    dispatch(stickyAtcModifyingBool(attribute, false));
    if (response.ok) {
      dispatch(activateToast('Success :)'));
      dispatch(stickyAtcActuallyModifyWidgetBool(attribute, bool));
    } else {
      dispatch(activateToast('Failed :('));
    }
  };
}

export function stickyAtcModifyNum(attribute, num, token) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(stickyAtcModifyingNum(attribute, true));
    const response = await updateNewStickyAtcApi(
      'api/shop',
      {
        shop: state.stickyAtc.domain,
        [attribute]: +state.stickyAtc.shopConfig[attribute],
      },
      token
    );
    dispatch(stickyAtcModifyingNum(attribute, false));
    if (response.ok) {
      dispatch(activateToast('Success :)'));
    } else {
      dispatch(activateToast('Failed :('));
    }
  };
}

export function stickyAtcActuallyModifyString(attribute, token) {
  return async (dispatch, getState) => {
    const state = getState();
    const response = await updateNewStickyAtcApi(
      'api/shop',
      {
        shop: state.stickyAtc.domain,
        [attribute]: state.stickyAtc.shopConfig[attribute] ? state.stickyAtc.shopConfig[attribute] : null,
      },
      token
    );
    if (response.ok) {
      dispatch(activateToast('Success :)'));
    } else {
      dispatch(activateToast('Failed :('));
    }
  };
}

export function stickyAtcWidgetTextChangeApi(attr, text, token) {
  return async (dispatch, getState) => {
    const state = getState();
    const response = await updateNewStickyAtcApi(
      'api/shop',
      {
        shop: state.stickyAtc.domain,
        [attr]: text,
      },
      token
    );
    if (response.ok) {
      dispatch(activateToast('Success :)'));
      dispatch(stickyAtcWidgetTextChange(attr, text));
    } else {
      dispatch(activateToast('Failed :('));
    }
  };
}


export function stickyAtcWidgetDevShopsAction(token) {
  return async (dispatch) => {
    dispatch(stickyAtcWidgetDevShopsActionLoading(true));
    const res = await actionNewStickyAtcApi(`api/widget-dev-action`, token);
    const data = await res.json();
    dispatch(stickyAtcWidgetDevShops(data));
    dispatch(stickyAtcWidgetDevShopsActionLoading(false));
  };
}

export function stickyAtcSubscribedNoViews(token) {
  return async (dispatch) => {
    dispatch(stickyAtcSubscribedNoViewsLoading(true));
    const res = await fetchNewStickyAtcApi(`api/sub-no-views`, token);
    if (res.ok) {
      const json = await res.json();
      dispatch(activateToast('Success :)'));
      dispatch(setSubscribedNoViewsJson(json));
    } else {
      dispatch(activateToast('Failed :('));
    }
    dispatch(stickyAtcSubscribedNoViewsLoading(false));
  };
}
export function stickyAtcGetAllPackages(token) {
  return async (dispatch, getState) => {
    dispatch(stickyAtcGettingAllPackages());
    const response = await fetchNewStickyAtcApi('api/package', token);
    if (response.ok) {
      const json = await response.json();
      dispatch(stickyAtcSetAllPackages(json));
    } else {
      console.log('stickyAtcGetAllPackages error');
    }
  };
}

export function stickyAtcGetAllCoupons(token) {
  return async (dispatch, getState) => {
    dispatch(stickyAtcGettingAllCoupons());
    const response = await fetchNewStickyAtcApi('api/coupon', token);
    if (response.ok) {
      const json = await response.json();
      dispatch(stickyAtcSetAllCoupons(json));
    } else {
      console.log('stickyAtcGetAllPackages error');
    }
  };
}
